<template>
  <base-card header="bg-info" body="bg-light shadow">
    <template #header>إنشاء مزاد</template>
    <template #body>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-6">
            <h3 class="text-success text-center">
              <span class="m-1">تم إنشاء المزاد بنجاح</span>
              <i class="m-1 fa fa-check-circle"></i>
            </h3>
            <br>
            <div class="buttons">
              <router-link class="btn btn-success" :to="showAuction">عرض المزاد</router-link>
              <router-link class="btn btn-info" :to="createAuction">مزاد جديد</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import Storage from "@/common/Storage";

export default {
  components: {BaseCard},
  computed: {
    auctionId() {
      const auctionId = Storage.get('created_auction');
      return !!auctionId && auctionId.data ? auctionId.data : '';
    },
    showAuction() {
      if (!!this.auctionId)
        return {name: 'auctions.edit', params: {auction: this.auctionId}}
      return this.createAuction;
    },
    createAuction() {
      return {name: 'auctions.create'}
    },
  },
  created() {
    if (!this.auctionId)
      this.$router.replace(this.createAuction)
  }
}
</script>

<style scoped>
.buttons {
  display         : flex;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
}

.buttons .btn {
  margin        : 0.25rem;
  border-radius : 0;
  box-shadow    : 0 .5rem 1rem rgba(0, 0, 0, .15)
}
</style>
